<template>
  <div>
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->
    <van-popup v-model="selectBSubShow" position="bottom">
      <van-picker
        title="渠道"
        show-toolbar
        :columns="[
          { text: '全部', value: false },
          { text: '银联', value: 'bank' },
          { text: '支付宝', value: 'alipay' },
          { text: '支付宝红包', value: 'alipay-hb' },
          { text: '数字人民币', value: 'ecny' },
          { text: '微信', value: 'wechat' },
          { text: '抖音红包', value: 'titok' },
        ]"
        @confirm="selectBank"
        @cancel="selectSubShow = false"
        position="bottom"
        cancel-button-text="取消"
        confirm-button-text="确认"
      >
      </van-picker>
    </van-popup>
    <van-sticky>
      <van-nav-bar :title="'个人报表'" @click-left="$router.push('/selfData')">
        <template #left>
          <van-icon name="arrow-left" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <div class="selectWrap">
        <div class="active">个人报表</div>
        <div @click="$router.push('teamReport')">团队报表</div>
      </div>
      <div class="cardWrap">
        <div style="display: flex; width: 90%">
          <date-range-picker
            style="
              width: 90%;
              margin-bottom: 10px;
              margin-right: 5px;
              min-width: 215px;
            "
            class="timePick teamReport"
            ref="picker"
            opens="center"
            :timePicker="false"
            v-model="dates"
            :locale-data="local"
            :ranges="false"
            :maxDate="getGMT_8(new Date())"
            :minDate="minDate()"
            :autoApply="true"
          >
            <template
              slot="input"
              v-if="dates.startDate == ''"
              style="min-width: 350px; padding-left: 15px"
            >
              <span style="min-width: 350px; padding-left: 15px">
                {{ "请输入日期时间" }}
              </span>
            </template>
          </date-range-picker>

          <div
            class="iosSelect"
            @click="selectBSubShow = true"
            style="width: 90%; margin-bottom: 15px; margin-left: 2px"
          >
            <span>{{ selectBSub.text || "渠道" }}</span>
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="cardBox">
          <p class="title">个人报表</p>
          <!-- <van-divider style="width: 100%; margin-top: 0px" /> -->
          <div class="content">
            <div class="box">
              <p>交易量(元)</p>
              <p class="balance">{{ reportData.trans_amount }}</p>
            </div>
            <div class="box">
              <p>提现(元)</p>
              <p class="balance">{{ reportData.withdraw_amount }}</p>
            </div>
            <div class="box">
              <p>{{ $returnBuySel("进货") }}红利(元)</p>
              <p class="balance">{{ reportData.bonus_payment }}</p>
            </div>
            <div class="box">
              <p>{{ $returnBuySel("出货") }}红利(元)</p>
              <p class="balance">{{ reportData.bonus_trans }}</p>
            </div>
            <div class="box">
              <p>当日{{ $returnBuySel("进货") }}数量(个)</p>
              <p class="balance">{{ reportData.today_p_count }}</p>
            </div>
            <div class="box">
              <p>下级返利(元)</p>
              <p class="balance">{{ reportData.bonus_trans_group }}</p>
            </div>
            <div class="box"></div>
          </div>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foot from "../../../components/footer";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { foot, DateRangePicker },
  data() {
    return {
      reportData: {},
      selectBSubShow: false,
      selectBSub: "",

      dates: { startDate: "", endDate: "" },
      local: {
        direction: "ltr",
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "确认",
        weekLabel: "W",
        cancelLabel: "取消",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
        monthNames: [
          "01月",
          "02月",
          "03月",
          "04月",
          "05月",
          "06月",
          "07月",
          "08月",
          "09月",
          "10月",
          "11月",
          "12月",
        ],
        firstDay: 0,
      },
      channelList: {
        bank: "银联",
        alipay: "支付宝",
        "alipay-hb": "支付宝红包",
        ecny: "数字人民币",
        wechat: "微信",
        titok: "抖音红包",
      },
    };
  },
  watch: {
    dates() {
      this.getReport();
    },
    selectBSub() {
      this.getReport();
    },
    selfData() {
      this.getReport();
    },
  },
  computed: {
    ...mapState([
      "selfData",
      // ...
    ]),
  },
  methods: {
    getGMT_8(timestamp) {
      const date = new Date(timestamp);
      const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
      date.setHours(GMT_8);
      return date;
    },
    minDate() {
      return this.getGMT_8(new Date(new Date().getTime() - 86400000 * 2));
    },
    selectBank(e) {
      this.selectBSub = e;
      this.selectBSubShow = false;
    },

    getReport() {
      if (this.selfData.member_id) {
        let sendData = {};
        if (this.dates.startDate) {
          sendData = {
            start: this.$returnTime(this.dates.startDate),
            end: this.$returnTime(this.dates.endDate),
          };
        }
        if (this.selectBSub.value || this.selectBSub.value === 0) {
          sendData.channel = this.selectBSub.value;
        }
        this.$http
          .get(`/mobile/reportPersonal`, { params: sendData })
          .then((res) => {
            if (res.data.code == 0) {
              this.reportData = res.data.data;
            }
          });
      }
    },
  },
  created() {
    this.getReport();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: rgba(241, 245, 254, 1);
  min-height: 100vh;
  font-size: 14px;
}
.cardWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .cardBox {
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding-bottom: 20px;
    font-weight: 400;
    .title {
      font-weight: bold;
      font-size: 15px;
      width: 100%;
      position: relative;
      margin: 10px 0;
      padding-left: 10px;
      margin-left: 40px;
      padding-top: 10px;
      &::before {
        content: "";
        position: absolute;
        height: 18px;
        left: 0;
        width: 4px;
        background-color: #4065f0;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .box {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .balance {
          font-weight: bold;
        }
        p {
          margin: 0;
          // padding: 5px;
          &:first-child {
            color: #999999;
          }
        }
      }
    }
  }
  b {
    width: 140px;
    height: 0;
  }
}

.selectWrap {
  width: 90%;
  display: flex;
  background-color: #4065f0;
  color: white;
  border-radius: 50px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 15px;
  div {
    width: 50%;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      margin: 5px;
      background: white;
      border-radius: 50px;
      color: #4065f0;
      font-weight: bold;
    }
  }
}
</style>
